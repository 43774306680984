import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Grid } from '@material-ui/core';
import Layout from '../components/layout';
import Banner from '../components/banner';
import RequestToKnow from '../components/RequestToKnow';
import RequestToDelete from '../components/RequestToDelete';

const addButtonListerner = (elementID, listener) => {
  let intervalID;
  // eslint-disable-next-line prefer-const
  intervalID = setInterval(() => {
    const requestToKnowButton = document.getElementById(elementID);
    if (requestToKnowButton) {
      requestToKnowButton.addEventListener('click', listener);
      clearInterval(intervalID);
    }
  }, 100);
};

const clearButtonListener = (elementID, listener) => {
  const requestToKnowButton = document.getElementById(elementID);
  requestToKnowButton.removeEventListener('click', listener);
};

export default function PrivacyPolicy({ data }) {
  const [openRequestToKnowModal, setOpenRequestToKnowModal] = React.useState(false);
  const [openRequestToDeleteModal, setOpenRequestToDeleteModal] = React.useState(false);

  React.useEffect(() => {
    const openToKnowModalListener = (event) => {
      event.preventDefault();
      setOpenRequestToKnowModal(true);
    };
    const openToDeleteModalListener = (event) => {
      event.preventDefault();
      setOpenRequestToDeleteModal(true);
    };
    addButtonListerner('requestToKnowBtn', openToKnowModalListener);
    addButtonListerner('requestToDeleteBtn', openToDeleteModalListener);
    return function cleanup() {
      clearButtonListener('requestToKnowBtn', openToKnowModalListener);
      clearButtonListener('requestToDeleteBtn', openToDeleteModalListener);
    };
  });

  const { page: { title, content } } = data;
  return (
    <Layout>
      <div style={{ padding: '20px' }}>
        <div id="back-to-top-anchor" />
        <Banner title={title} />
        <Grid className="privacyContainer">
          <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
        </Grid>
        <RequestToKnow
          email="requesttoknow@seniorsavings.com"
          siteName="seniorsavings.com"
          openModal={openRequestToKnowModal}
          closeModal={() => setOpenRequestToKnowModal(false)}
        />
        <RequestToDelete
          email="requesttodelete@seniorsavings.com"
          siteName="seniorsavings.com"
          openModal={openRequestToDeleteModal}
          closeModal={() => setOpenRequestToDeleteModal(false)}
        />
      </div>
      <div
        style={{
          padding: 20,
          fontSize: 15,
          paddingTop: '0',
        }}
      >
        Last Updated: 7/29/2021
      </div>
    </Layout>
  );
}

PrivacyPolicy.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export const query = graphql`query {
  page: wpPage(title: { eq: "Privacy Policy" }) {
      title
      content
    }
}`;
